<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="700px" @close="commitOnClose">
            <el-form ref="saveOrEditForm" :model="saveData" label-width="150px" style="width:600px" :rules="rules">
                <el-form-item label="代理商/机构编号" prop="userNo">
                    <el-select style="width:100%"
                            v-model="userNo"
                            filterable
                            remote
                            reserve-keyword
                            @change="getUserName"
                            placeholder="可根据代理商/机构编号模糊查询"
                            :remote-method="remoteMethod"
                            :loading="loading">
                            <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.agentNo"
                            :value="item">
                            </el-option>
                        </el-select>
                </el-form-item>
                <el-form-item label="代理商/机构名称" prop="userName">
                {{saveData.userName}}
                </el-form-item>
                <el-form-item label="止付金额" prop="suspendAmount">
                    <el-input v-model="saveData.suspendAmount" style="width:400px"  v-numberInt:2
                              placeholder="请输入止付金额"/> 元
                </el-form-item>
                <el-form-item label="止付类型" prop="suspendType">
                    <el-select v-model="saveData.suspendType" placeholder="请选择止付类型" style="width: 240px">
                    <el-option label="机具款项" value="1"></el-option>
                    <el-option label="风险止付" value="2"></el-option>
                    <el-option label="活动止付" value="3"></el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="saveData.remark" type="textarea"
                              placeholder="请最多输入30字"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitCheck">确定新增</el-button>
                <el-button type="primary" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {FinancialToolsApi} from '@/api';
    export default {
        name: "CustomForm",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            isAdd: {
                type: Boolean,
                default: true,
            },
            selectRow: {
                type: Object,
                default: function () {
                    return {}
                },
            },
            onFresh: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                title: '',
                saveData: {},
                open: false,
                rules:{
                    userNo:[{ required: true, message: '请选择代理/机构编号', trigger: 'change' }],
                    suspendAmount:[{ required: true, message: '请输入止付金额', trigger: 'blur' }],
                    suspendType:[{ required: true, message: '请选择止付类型', trigger: 'change' }],
                },
                options:[],
                userNo:'',
            }
        },
        watch: {
            onFresh: function (value) {
                this.open = value;
                if (value) {
                    this.resetData();
                }
            }
        },
        mounted() {
        },
        methods: {
            async remoteMethod(query){
                if(query!=''){
                let res = await FinancialToolsApi.suspendOrder.queryAgentInfo(query)
                if(res.success){
                    this.options = res.data
                }
                } else{
                    this.options = []
                }
            },
            submitCheck(){
                this.$refs.saveOrEditForm.validate((valid) => {
                    if (valid) {
                        this.submitForm()
                    } else {
                        return false;
                    }
                });
            },
            async resetData() {
                if (this.isAdd){
                    this.title = "新增止付";
                    this.saveData = {};
                }else {
                    this.saveData = this.selectRow;
                    this.isAdd = false;
                    this.title = "修改止付";
                }
            },
            async submitForm() {
                let result = (this.isAdd) ?
                        (await FinancialToolsApi.suspendOrder.save(this.saveData)) :
                        (await FinancialToolsApi.suspendOrder.edit(this.saveData.id, this.saveData));
                if (result.success) {
                    this.Message.success(this.isAdd ? '添加成功' : '修改成功');
                    this.saveData.userName = ''
                    this.saveData.userNo = ''
                    this.saveData.suspendAmount = ''
                    this.saveData.suspendType = ''
                    this.saveData.remark = ''
                    this.userNo = ''
                    this.commitOnClose();
                }
            },
            cancel() {
                this.saveData = {};
                this.saveData.userName = ''
                this.saveData.userNo = ''
                this.saveData.suspendAmount = ''
                this.saveData.suspendType = ''
                this.saveData.remark = ''
                this.userNo = ''
                this.commitOnClose();
            },
            commitOnClose() {
                this.$emit('on-close');
            },
            getUserName(item){
                console.log(item)
                this.saveData.userName = item.userName
                this.saveData.userNo = item.agentNo
                this.userNo = item.agentNo
            }
        }
    };
</script>

<style scoped>

</style>
